export const eventInformation = async (eventPassword) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + '/events/' + eventPassword, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            return {success: false};
        }
        const responseData = await response.json();
        responseData.success = true;

        return responseData;
    } catch (error) {
        console.error(error);
        return {success: false};
    }
};

export const validateTicket = async (eventPassword, ticketNumber) => {
    try {
        var raw = JSON.stringify({
            "eventPassword": eventPassword,
            "ticketNumber": parseInt(ticketNumber),
        });

        const response = await fetch(process.env.REACT_APP_API_URL + '/validate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: raw
        });

        if (!response.ok) {
            return {success: false};
        }
        const responseData = await response.json();

        return responseData;
    } catch (error) {
        console.error(error);
        return {success: false};
    }
};
import logo from './logo.png';
import './../App.css';
import { Button, Form } from 'react-bootstrap';
import { eventInformation } from './../api.js';
import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import {setCookie} from "../Utils";
import { useNavigate } from "react-router-dom";

function LoginPage() {
    const [passwordValue, setPasswordValue] = useState('');
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        let result = await eventInformation(passwordValue)
        if(result.success) {
            setCookie('eventHash', passwordValue, 12);
            return navigate("/validation");
        } else {
            setError(true);
        }
    };

    return (
        <div className="Login">
            <article className="App-header">
                <img src={logo} style={{maxWidth: '300px', height: 'auto'}} className="App-logo" alt="logo" />
                {error && (
                    <Alert variant='danger'>
                        Contraseña inválida!
                    </Alert>
                )}

                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label></Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Contraseña de evento"
                            value={passwordValue}
                            onChange={(event) => setPasswordValue(event.target.value)}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Entrar
                    </Button>
                </Form>

            </article>
        </div>
    );
}

export default LoginPage;

import React, {useRef, useEffect, useState} from 'react';
import './../App.css';
import QrScanner from 'qr-scanner';
import {validateTicket} from "../api";
import {getCookie} from "../Utils";
import {useNavigate} from "react-router-dom";

function ValidationQR() {
    const videoRef = useRef(null);
    const navigate = useNavigate();

    const lastTicketRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const resultScanning = async (result) => {
        if(result.data!==lastTicketRef.current) {
            setIsLoading(true);
            lastTicketRef.current = result.data;
            const response = await validateTicket(getCookie('eventHash'), result.data)
            if(response.success){
                alert('Validado Ok. Entrada: ' + response.ticketType +
                    '. Validada ' + response.validatedTicketsInPurchase
                    + ' de ' + response.totalTicketsInPurchase);
            } else {
                alert('Error: ' + response.errorMessage);
            }
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (!getCookie('eventHash')) {
            return navigate("/");
        }
        const qrScanner = new QrScanner(
            videoRef.current,
            result => resultScanning(result),
            {
                highlightScanRegion: true,
                highlightCodeOutline: true,
                returnDetailedScanResult: false
            }

        );
        qrScanner.start();

        return () => {
            qrScanner.destroy();
        };

    }, []);

    return (

        <div className="App d-flex flex-column min-vh-100" >
            <div style={{ display: isLoading ? 'none' : 'block' }}>
                <video ref={videoRef} width={'100%'} height={'100%'} ></video>
            </div>
            <div style={{ display: isLoading ? 'block' : 'none' }}>
                <h1>Cargando...</h1>
            </div>

        </div>
    );

}

export default ValidationQR;
export const setCookie = (nombre, valor, duracionHoras) =>  {
    const fechaExpiracion = new Date();
    fechaExpiracion.setTime(fechaExpiracion.getTime() + duracionHoras * 60 * 60 * 1000);

    const cookie = `${nombre}=${encodeURIComponent(valor)}; expires=${fechaExpiracion.toUTCString()}; path=/`;
    document.cookie = cookie;
}

export const getCookie = (nombre) => {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(nombre + '=')) {
            return decodeURIComponent(cookie.substring(nombre.length + 1));
        }
    }

    return null;
}
import React, { useEffect, useState } from "react";
import { getCookie } from "../Utils";
import { useNavigate } from "react-router-dom";
import { eventInformation } from "./../api.js";
import "./../App.css";
import { Button, ProgressBar } from "react-bootstrap";
import logo from "./logo.png";
import ValidationQR from "../Components/ValidationQr";
import ValidationNumber from "../Components/ValidationNumber";
import ToastComp from "../Components/Toast";

function deleteCookie(name) {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

function Validation() {
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [messageTitle, setMessageTitle] = useState("");
  const [message, setMessage] = useState("");
  const [toast, setToast] = useState(false);

  const backToLogin = () => {
    deleteCookie("eventHash");
    return navigate("/");
  };

  useEffect(() => {
    const hash = getCookie("eventHash");
    if (!hash) {
      return navigate("/");
    }
    const getEventData = async () => {
      const data = await eventInformation(hash);
      setEvent(data);
    };

    getEventData();
  }, []);

  return (
    <div className="App d-flex flex-column">
      <div className="header">
        <img
          src={logo}
          style={{ width: "auto", height: "auto", maxWidth: "170px" }}
          className="App-logo"
          alt="logo"
        />
        <Button variant="link" onClick={() => backToLogin()}>
          Salir
        </Button>
      </div>

      <ToastComp messageTitle={messageTitle} message={message} toast={toast} />

      <ValidationQR
        setEvent={setEvent}
        setMessageTitle={setMessageTitle}
        setMessage={setMessage}
        setToast={setToast}
      />

      <ValidationNumber
        setEvent={setEvent}
        setMessageTitle={setMessageTitle}
        setMessage={setMessage}
        setToast={setToast}
      />

      <div className="box">
        <h3 style={{ marginBottom: "20px" }}>{event?.event}</h3>
        <div className="ticket">
          <p>Entradas validadas:</p>
          <p className="bold"> {event?.ticketsValidated}</p>
        </div>
        <div className="ticket">
          <p>Entradas vendidas: </p>
          <p className="bold">{event?.ticketsSold}</p>
        </div>
        <ProgressBar
          striped
          animated
          variant="info"
          now={Math.round((event?.ticketsValidated * 100) / event?.ticketsSold)}
          className="mt-4"
        />
      </div>
    </div>
  );
}

export default Validation;
